import PropTypes from '+prop-types';
import { Fragment } from 'react';

import Field from '+components/form/FinalForm/Field';
import MultiSelectField from '+components/form/MultiSelectField';
import { normalizeMultiSelectValue } from '+components/form/Normalizers';
import RoleAuthFieldsSection from '+components/form/RoleAuthFieldSection';
import TextField from '+components/form/TextField';
import { validateRequired } from '+components/form/Validators';

const AzureFields = ({ disabled, tags }) => {
  return (
    <Fragment>
      <Field
        name="azure.subscriptionid"
        label="Subscription ID"
        component={TextField}
        type="text"
        validate={validateRequired}
        helperText="Azure subscription id to be queried"
        disabled={disabled}
        required
      />

      <Field
        name="azure.tenantid"
        label="Tenant ID"
        component={TextField}
        type="text"
        validate={validateRequired}
        helperText="Azure tenant id to use"
        disabled={disabled}
        required
      />

      <Field
        name="azure.tags"
        label="Tag/Label Matches"
        helperText="One or many tags to match any custom tag names to track in Azure"
        options={tags}
        component={MultiSelectField}
        parse={normalizeMultiSelectValue}
        disabled={disabled}
        allowCreate
      />

      <RoleAuthFieldsSection label="Authentication">
        <Field
          name="azure.clientid"
          label="Application Client ID"
          component={TextField}
          type="text"
          validate={validateRequired}
          helperText="Client id to use authenticating with Azure"
          disabled={disabled}
          required
        />
        <Field
          name="azure.clientsecret"
          label="Client Secret Value"
          component={TextField}
          type="password"
          autoComplete="off"
          validate={validateRequired}
          helperText="Client secret to use authenticating with Azure"
          disabled={disabled}
          required
        />
      </RoleAuthFieldsSection>
    </Fragment>
  );
};

AzureFields.propTypes = {
  disabled: PropTypes.bool,
  tags: PropTypes.array,
};

AzureFields.defaultProps = {
  disabled: false,
  tags: [],
};

export default AzureFields;
